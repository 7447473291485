import {
    IContentMark,
    IMassStickersUpdate, IMemesIndexSearchParams, IMemesIndexSearchResult,
    IModerationShift,
    IModeratorAreaPageLoadParams,
    ISticker,
    IStickerUpdate,
    IStreamerInfoMoneyItem,
    IStreamerInfoMoneyLoadParams,
    IStreamerInfoStickersItem,
    IStreamerInfoStickersLoadParams,
    IUser,
    IViewerInfo,
    IViewerInfoLoadParams,
} from '@typings';

import { baseApi } from './base.api';

export const moderationApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Moderation_ModerationShift',
            'Moderation_QueueLength',
            'Moderation_BadWords',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            /* USERS MODERATION */
            getPrivateUserByName: build.query<IUser, string>({
                query: (username?: string) => ({
                    url: 'moderation/users/find',
                    method: 'POST',
                    body: { username },
                }),
            }),

            deleteUserById: build.query<boolean, string>({
                query: (userId?: string) => ({
                    url: 'moderation/users/delete',
                    method: 'POST',
                    body: { userId },
                }),
            }),

            restoreUserById: build.query<boolean, string>({
                query: (userId?: string) => ({
                    url: 'moderation/users/restore',
                    method: 'POST',
                    body: { userId },
                }),
            }),

            /* MODERATION SHIFTS */
            getCurrentModerationShift: build.query<IModerationShift, undefined>({
                query: () => ({
                    url: 'moderation/shift',
                    method: 'POST',
                }),
                providesTags: ['Moderation_ModerationShift'],
            }),

            openModerationShift: build.mutation<IModerationShift, void>({
                query: () => ({
                    url: 'moderation/open-shift',
                    method: 'POST',
                }),
                invalidatesTags: ['Moderation_ModerationShift', 'Moderation_QueueLength'],
            }),

            closeModerationShift: build.mutation<IModerationShift, void>({
                query: () => ({
                    url: 'moderation/close-shift',
                    method: 'POST',
                }),
                invalidatesTags: ['Moderation_ModerationShift', 'Moderation_QueueLength'],
            }),

            /* MODERATION ROOM */
            getNextModeratedSticker: build.query<ISticker, { skipCurrent?: boolean }>({
                query: (data) => ({
                    url: 'moderation/room/next-sticker',
                    method: 'POST',
                    body: data,
                }),
            }),

            getModeratedStickersCount: build.query<number, void>({
                query: (data) => ({
                    url: 'moderation/room/queue-length',
                    method: 'GET',
                    body: data,
                }),
                providesTags: ['Moderation_QueueLength'],
            }),

            /* STICKER CRUD */
            updateSticker: build.mutation<ISticker, IStickerUpdate>({
                query: (data: IStickerUpdate) => ({
                    url: 'sticker',
                    method: 'PUT',
                    body: data,
                }),
                invalidatesTags: ['Moderation_QueueLength'],
            }),

            /* MASS STICKER UPDATE */
            massUpdateStickers: build.mutation<number, IMassStickersUpdate>({
                query: (data: IMassStickersUpdate) => ({
                    url: 'moderation/sticker/mass-update',
                    method: 'POST',
                    body: data,
                }),
            }),

            /* STICKERS PAGE */
            loadStickersPage: build.query<ISticker[], IModeratorAreaPageLoadParams>({
                query: (params: IModeratorAreaPageLoadParams) => ({
                    url: 'moderation/stickers-page',
                    method: 'POST',
                    body: params,
                }),
            }),

            loadStickerToEdit: build.query<ISticker, { stickerId: string; }>({
                query: (params) => ({
                    url: 'moderation/stickers-page/sticker',
                    method: 'POST',
                    body: params,
                }),
            }),

            /* CONTENT MARKS */
            loadContentMarks: build.query<IContentMark[], void>({
                query: () => ({
                    url: `content-mark/list`,
                    method: 'GET',
                }),
            }),

            /* USER */
            giveBonus: build.query<void, {userId: string; streamerId: string; value: number;}>({
                query: (data) => ({
                    url: 'user/give-bonus',
                    method: 'POST',
                    body: data,
                }),
            }),

            /* VIEWERS PAGE */
            getViewerInfoPage: build.query<IViewerInfo, IViewerInfoLoadParams>({
                query: (data: IViewerInfoLoadParams) => ({
                    url: `moderation/viewers-page/info`,
                    method: 'POST',
                    body: data,
                }),
            }),

            /* STREAMERS PAGE */
            getStreamerInfoMoneyPage: build.query<IStreamerInfoMoneyItem[], IStreamerInfoMoneyLoadParams>({
                query: (data: IStreamerInfoMoneyLoadParams) => ({
                    url: `moderation/streamers-page/money/${data.page}`,
                    method: 'POST',
                    body: data,
                }),
            }),

            getStreamerInfoStickersPage: build.query<IStreamerInfoStickersItem[], IStreamerInfoStickersLoadParams>({
                query: (data: IStreamerInfoStickersLoadParams) => ({
                    url: `moderation/streamers-page/stickers/${data.page}`,
                    method: 'POST',
                    body: data,
                }),
            }),

            /* BAD WORDS */
            getBadWords: build.query<{ badWords: string }, string>({
                query: (dictionaryName: string) => ({
                    url: `moderation/bad-words/${dictionaryName}`,
                    method: 'GET',
                }),
                providesTags: ['Moderation_BadWords'],
            }),

            saveBadWords: build.mutation<boolean, { badWords: string, dictionaryName: string }>({
                query: (data) => ({
                    url: `moderation/bad-words/${data.dictionaryName}`,
                    method: 'POST',
                    body: { badWords: data.badWords },
                }),
                invalidatesTags: ['Moderation_BadWords'],
            }),

            checkBadWords: build.query<{ result: string }, { query: string, dictionaryName: string }>({
                query: (data) => ({
                    url: `moderation/bad-words/check/${data.dictionaryName}`,
                    method: 'POST',
                    body: { query: data.query },
                }),
            }),

            /* MEMES INDEX */
            searchSimilarStickers: build.query<IMemesIndexSearchResult, IMemesIndexSearchParams>({
                query: (params: IMemesIndexSearchParams) => ({
                    url: 'sticker/index/search',
                    method: 'POST',
                    body: params,
                }),
            }),
        }),
    });
