import { useEffect, useState } from 'react';

import { api } from '@store';
import { IViewerInfo, IViewerInfoEventItem } from '@typings';

export enum ViewerInfoMode {
    EVENTS,
    WALLETS,
}

export interface IViewerInfoParams {
    userId: string;
}

const PAGE_SIZE = 20;

export const useViewerInfo = (params: IViewerInfoParams) => {
    const { userId } = params;

    const {
        moderation: {
            useLazyGiveBonusQuery,
            useLazyDeleteUserByIdQuery,
            useLazyRestoreUserByIdQuery,
            useLazyGetViewerInfoPageQuery,
        },
    } = api;

    const [page, setPage] = useState(1);
    const [currentStats, setCurrentStats] = useState<IViewerInfoEventItem[]>([]);
    const [lastData, setLastData] = useState<IViewerInfo>();
    const [isEndReached, setIsEndReached] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);

    const [
        giveBonusRequest,
    ] = useLazyGiveBonusQuery();

    const [
        deleteUserRequest,
    ] = useLazyDeleteUserByIdQuery();

    const [
        restoreUserRequest,
    ] = useLazyRestoreUserByIdQuery();

    const [
        load,
        {
            isLoading: isLoadingPage,
            isSuccess,
            isFetching: isFetchingPage,
        },
    ] = useLazyGetViewerInfoPageQuery();

    const loadPage = async (page: number) => {
        const loadedPage = await load({ skip: (page - 1) * PAGE_SIZE, limit: PAGE_SIZE, userId }).unwrap();

        if (loadedPage && !loadedPage.events?.length) {
            if (loadedPage.viewer) {
                setIsNotFound(false);
                setIsEndReached(true);
                setLastData(loadedPage);
            } else {
                setIsNotFound(true);
                setIsEndReached(false);
                setLastData(undefined);
                setCurrentStats([]);
            }
        } else {
            setIsNotFound(false);

            if (page === 1) {
                setCurrentStats(loadedPage?.events ?? []);
            } else {
                setCurrentStats((prev) => [...prev].concat(loadedPage?.events ?? []));
            }

            setLastData(loadedPage);
        }
    };

    const reloadCurrent = async () => {
        const loadedPage = await load({ skip: 0, limit: page * PAGE_SIZE, userId }).unwrap();

        if (loadedPage) {
            setLastData(loadedPage);
            setCurrentStats(loadedPage?.events ?? []);
        }
    };

    const giveBonus = async (streamerId: string, value?: number) => {
        if (lastData?.viewer && value && value > 0) {
            await giveBonusRequest({ userId: lastData?.viewer.id, streamerId, value }).unwrap();
            await reloadCurrent();
        }
    };

    const deleteUser = async (userId: string) => {
        if (userId) {
            await deleteUserRequest(userId).unwrap();
            setIsNotFound(false);
            setIsEndReached(false);
            setLastData(undefined);
            setCurrentStats([]);
            setPage(1);
            find();
        }
    };

    const restoreUser = async (userId: string) => {
        if (userId) {
            await restoreUserRequest(userId).unwrap();
            setIsNotFound(false);
            setIsEndReached(false);
            setLastData(undefined);
            setCurrentStats([]);
            setPage(1);
            find();
        }
    };

    const find = async () => {
        if (userId) {
            await loadPage(1);
        }
    };

    const loadNextPage = () => {
        setPage(page + 1);
    };

    useEffect(() => {
        setIsNotFound(false);
        setIsEndReached(false);
        setLastData(undefined);
        setCurrentStats([]);
        setPage(1);
        find();
    }, [userId]);

    useEffect(() => {
        if (userId && page > 1) {
            loadPage(page);
        }
    }, [page]);

    useEffect(() => {
        loadPage(1);
    }, []);

    return {
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isSuccess,
        isNotFound,

        loadNextPage,
        find,
        giveBonus,
        deleteUser,
        restoreUser,
    };
};
