import {
    Button,
    Column,
    EComponentColor,
    EComponentSize,
    ERowJustification,
    Label,
    Row,
    TextArea,
    useModals,
} from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import { t } from 'i18next';
import React, { useState } from 'react';

import { IconCloseLine } from '@assets/images/svg';

import s from './RejectStickerPopup.scss';


interface RejectStickerPopupProps {
    onRejectClick?: (reason: string) =>void;
}

export const RejectStickerPopup = (props: RejectStickerPopupProps) => {
    const { closeModal } = useModals();
    const {
        onRejectClick = noop,
    } = props;

    const [rejectReason, setRejectReason] = useState('');

    const onSaveClick = () => {
        onRejectClick(rejectReason);
        closeModal();
    };

    return (
        <div className={s.rejectStickerPopup}>
            <Column rowGap={10} className={s.rejectStickerPopupContent}>
                <Label caption={t('reject-sticker-popup.reject-reason')} size={EComponentSize.MEDIUM} style={{ padding: 0 }}/>
                <Row>
                    <TextArea
                        maxRows={5}
                        color={EComponentColor.ACCENT}
                        onChange={(val) => setRejectReason(val?.toString() ?? '')}
                    />
                </Row>
                <Row columnGap={10} justification={ERowJustification.BEGIN}>
                    <Button
                        color={EComponentColor.DANGER}
                        caption={t('moderator-area.common.reject')}
                        onClick={onSaveClick}
                    />
                    <Button
                        color={EComponentColor.ACCENT}
                        caption={t('common.cancel')}
                        onClick={closeModal}
                    />
                </Row>
            </Column>

            <div className={s.closeButton} onClick={closeModal}>
                <IconCloseLine/>
            </div>
        </div>
    );
};
