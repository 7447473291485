import {
    Button,
    Column,
    Row,
    ERowJustification,
    TextField,
} from '@hyperclap/ui';
import React, { useState } from 'react';

import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { DesktopPage } from '@views';

import { Header } from '../../components';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

/** Desktop layout of the landing properties */
interface IDesktopProps extends TApp {}

export const Desktop = (props: IDesktopProps) => {
    const {
        login: {
            login,
        },
        translation: {
            t,
        },
    } = props;

    const [username, setUsername] = useState<string>();
    const [password, setPassword] = useState<string>();
    const [hasError, setHasError] = useState<boolean>(false);

    const onUsernameChange = (val: string) => {
        setUsername(val);
        resetError();
    };

    const onPasswordChange = (val: string) => {
        setPassword(val);
        resetError();
    };

    const resetError = () => {
        setHasError(false);
    };

    const onLoginAction = async () => {
        if (username && password) {
            const result = await login(username.toString(), password.toString());

            if (!result) {
                setHasError(true);
            }
        }
    };

    const header = <Header {...props}/>;
    const footer = <Footer {...props}/>;

    return (
        <DesktopPage header={header} footer={footer}>
            <Column grow={true}>
                <Row justification={ERowJustification.CENTER} grow={true}>
                    <Column rowGap={20}>
                        <Row justification={ERowJustification.CENTER}>
                            <TextField
                                placeholder="Email"
                                width={ 250 }
                                onChanged={(val) => onUsernameChange(val)}
                            ></TextField>
                        </Row>
                        <Row>
                            <TextField
                                placeholder="Password"
                                width={ 250 }
                                type="password"
                                hasError={hasError}
                                errorMessage={t('auth.auth-error')}
                                onChanged={(val) => onPasswordChange(val)}
                            ></TextField>
                        </Row>
                        <Row>
                            <Button
                                caption={t('auth.login')}
                                style={{ width: 250, height: 55, borderRadius: 15 }}
                                disabled={!(username && password)}
                                onClick={() => onLoginAction()}
                            />
                        </Row>
                    </Column>
                </Row>
            </Column>
        </DesktopPage>
    );
};
