import {
    applyMargin,
    applyPadding,
    Column,
    Row,
    Switch,
    ThemeContext,
    ETheme,
    EComponentColor,
    ERowJustification,
    EHorizontalRelativePosition,
    IBlockComponentProps,
    IPadding,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { CSSProperties, useContext } from 'react';
import { Link } from 'react-router-dom';

import { DEFAULT_MAX_CONTENT_WIDTH, getCookie, setCookie } from '@common';
import { TApp } from '@hooks';
import { DEFAULT_SYSTEM_LANGUAGE, DEFAULT_SYSTEM_LANGUAGES, ILanguage, LANGUAGE_COOKIE_NAME } from '@typings';
import { LanguageSelector, Logo } from '@views';

import s from './Footer.scss';


interface FooterProps extends IBlockComponentProps, TApp {
    hideThemeSwitch?: boolean;
}

const FOOTER_PADDING: IPadding = { top: 15, bottom: 15 };
const MOBILE_FOOTER_PADDING: IPadding = { left: 20, right: 20 };

export const Footer = (props: FooterProps) => {
    const { theme, changeTheme } = useContext(ThemeContext);
    const {
        className,
        padding,
        margin,
        style,
        width,
        height,
        hideThemeSwitch,
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        translation: {
            t, i18n,
        },
    } = props;

    const footerClassNames = cn({
        [s.footer]: true,
        [s.footerMobileLayout]: isMobileLayoutRecommended,
        className,
    });

    const footerStyles: CSSProperties = {
        ...style,
        height,
        width,
        maxWidth: isMobileLayoutRecommended ? 'auto' : DEFAULT_MAX_CONTENT_WIDTH,
    };

    applyMargin(footerStyles, margin);
    applyPadding(footerStyles, padding);

    const onLanguageChanged = (lang: ILanguage) => {
        setCookie(LANGUAGE_COOKIE_NAME, lang.shortName);
        void i18n.changeLanguage(lang.shortName);
    };

    return (
        <Row columnGap={65} padding={FOOTER_PADDING} className={footerClassNames} style={footerStyles}>
            {!isMobileLayoutRecommended &&
                <Column>
                    <Logo grayscale width={66} className={s.logo}/>
                </Column>
            }

            {isMobileLayoutRecommended &&
                <Column>
                    <Row padding={MOBILE_FOOTER_PADDING} columnGap={4} className={s.da}>
                        Использует технологию
                        <Link to={'https://donationalerts.com/'} className={s.daLink} target="_blank">
                            {'DonationAlerts'}
                        </Link>
                    </Row>
                </Column>
            }

            {!isMobileLayoutRecommended &&
                <Column grow>
                    <Row justification={ERowJustification.END}>
                        <LanguageSelector
                            language={
                                DEFAULT_SYSTEM_LANGUAGES.find((l) => l.shortName === getCookie(LANGUAGE_COOKIE_NAME)) ||
                                DEFAULT_SYSTEM_LANGUAGE
                            }
                            languages={DEFAULT_SYSTEM_LANGUAGES}
                            onChange={onLanguageChanged}
                        />
                    </Row>
                </Column>
            }

            {!isMobileLayoutRecommended && !hideThemeSwitch &&
                <Column>
                    <Switch
                        caption={t('common.darkTheme')}
                        captionPosition={EHorizontalRelativePosition.RIGHT}
                        color={EComponentColor.ACCENT}
                        checked={theme === ETheme.DARK}
                        onChange={(t) => changeTheme(t ? ETheme.DARK : ETheme.LIGHT)}
                    />
                </Column>
            }
        </Row>
    );
};
