import { Column, ETheme, Row, ThemeContext } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext } from 'react';

import { TApp } from '@hooks';
import { Logo, LogoKind } from '@memealerts/views';

import s from './Header.scss';


interface IHeaderProps extends TApp {}

export const Header = (props: IHeaderProps) => {
    const {
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        navigation: {
            goToHome,
        },
    } = props;

    const { theme } = useContext(ThemeContext);

    return (
        <Row grow className={cn(s.header, { [s.headerMobileLayout]: isMobileLayoutRecommended })}>
            <Column>
                <Logo
                    width={92}
                    kind={theme === ETheme.LIGHT ? LogoKind.LIGHT : LogoKind.DARK}
                    onClick={goToHome}
                />
            </Column>

            <Column grow/>
        </Row>
    );
};
