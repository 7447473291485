import { api } from '@store';
import { ISticker } from '@typings';

export const useSimilarStickers = () => {
    const {
        moderation: {
            useLazySearchSimilarStickersQuery,
        },
    } = api;

    const [
        searchSimilarStickers,
        {
            data: stickers,
            isLoading: isLoad,
            isFetching: isFetching,
        },
    ] = useLazySearchSimilarStickersQuery();

    const search = (sticker: ISticker) => {
        searchSimilarStickers({ stickerId: sticker.id });
    };

    const isLoading = isLoad || isFetching;

    return {
        stickers,
        isLoading,
        search,
    };
};
