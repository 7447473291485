import { Logger } from '@hyperclap/ui';

import { TSettingsList } from '@typings';

import { baseApi } from './base.api';

const logger = new Logger({ target: 'SettingsApi', showTimestamp: true });
logger.trace(`Configure Settings API`);

export const settingsApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Settings_List',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            loadSettings: build.query<TSettingsList, void>({
                query: () => ({
                    url: 'settings',
                    method: 'GET',
                }),
                providesTags: ['Settings_List'],
            }),
        }),
    });
