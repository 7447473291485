import {
    Button,
    Column,
    EColumnJustification,
    EComponentColor,
    ETheme,
    Row,
    ThemeContext,
    useClickOutside,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useContext, useRef, useState } from 'react';

import { Avatar } from '@components';
import { TApp, useModerationShift } from '@hooks';
import { Logo, LogoKind } from '@memealerts/views';
import { ProfileMenu } from '@views';

import s from './Header.scss';

interface IHeaderProps extends TApp {
    maxWidth?: number;
}

export const Header = (props: IHeaderProps) => {
    const {
        currentUser: {
            currentUser,
            logout,
        },
        deviceInfo: {
            isMobileLayoutRecommended,
        },
        navigation: {
            goToModerationRoom,
            goToModerationStickersTab,
        },
        translation: {
            t,
        },
    } = props;

    const isCurrentUserHasModerFlag = currentUser?.flags?.isModer;

    const {
        currentModerationShift,
        queueLength,
        isCurrentModerationShiftFetching,
        toggleShift,
    } = useModerationShift({ currentUser, active: isCurrentUserHasModerFlag });

    const { theme } = useContext(ThemeContext);

    const avatarRef = useRef<HTMLDivElement>(null);

    useClickOutside({
        controlledElementRef: avatarRef,
        handler: () => setIsMenuVisible(false),
    });

    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const logoLeftPadding = isMobileLayoutRecommended ? 15 : 24;

    const onLogoClick = () => {
        if (currentUser?.flags?.isAdmin || currentUser?.flags?.isMasterModer) {
            goToModerationStickersTab();
        } else if (currentUser?.flags?.isModer) {
            goToModerationRoom();
        }
    };

    return (
        <Row grow className={cn(s.personalAreaHeader, { [s.personalAreaHeaderMobileLayout]: isMobileLayoutRecommended })}>
            <Column width={176} padding={{ left: logoLeftPadding }} justification={EColumnJustification.CENTER}>
                <Logo
                    width={96}
                    kind={theme === ETheme.LIGHT ? LogoKind.LIGHT : LogoKind.DARK}
                    onClick={onLogoClick}
                />
            </Column>
            {isCurrentUserHasModerFlag &&
                <Column style={{ minWidth: 100, paddingRight: 20 }}>
                    <div className={s.queue} onClick={goToModerationRoom}>
                        {t('moderator-area.moderation-shift.queue')}: {queueLength}
                    </div>
                </Column>
            }
            {isCurrentUserHasModerFlag && !isCurrentModerationShiftFetching &&
                <Column>
                    <Button
                        caption={currentModerationShift?.isOpen ? t('moderator-area.moderation-shift.close-shift')
                            : t('moderator-area.moderation-shift.open-shift')}
                        color={!currentModerationShift?.isOpen ? EComponentColor.WARNING : EComponentColor.ACCENT}
                        onClick={toggleShift}
                    />
                </Column>
            }
            <Column grow/>
            <Column
                className={cn(
                    s.personalAreaHeaderAvatarContainer,
                    { [s.personalAreaHeaderAvatarContainerMobileLayout]: isMobileLayoutRecommended },
                )}
                ref={avatarRef}
            >
                <Avatar source={currentUser?.avatar} isUserAvatar rounded onClick={() => setIsMenuVisible(!isMenuVisible)}/>
                { isMenuVisible && currentUser &&
                    <ProfileMenu className={s.profileMenu} currentUser={currentUser} onLogout={logout}/>
                }
            </Column>
        </Row>
    );
};
