import { noop } from '@hyperclap/utils';
import { Tuple } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { IGame } from '@typings';
import { GameSelectDefaultValue } from '@views';

interface GameSelectParams {
    debounceTime?: number;
    defaultValue?: GameSelectDefaultValue;
    invalidSymbols?: RegExp;
    maxLength?: number;
    readonly?: boolean;
    onChanged?: (value: string) => void;
    onSelect?: (game?: IGame) => void;
}

let timer: NodeJS.Timeout;

export const useGameSelect = (params: GameSelectParams) => {
    const defaultOnChange = (newValue: string) => {
        if (newValue.length > 0) {
            search(newValue);
        } else {
            setSearchResults([]);
        }
    };

    const {
        debounceTime = 0,
        defaultValue,
        invalidSymbols,
        maxLength = 0,
        readonly,
        onChanged = defaultOnChange,
        onSelect = noop,
    } = params;

    const [defaultInitialized, setDefaultInitialized] = useState(false);
    const [value, setValue] = useState<string>('');
    const [searchResults, setSearchResults] = useState<IGame[]>();
    const [selectedGame, setSelectedGame] = useState<IGame>();
    const [isImagesPreloaded, setIsImagesPreloaded] = useState(true);
    const [errorUrls, setErrorUrls] = useState<string[]>();

    const {
        stickers: {
            useLazyFindGameQuery,
        },
    } = useApi();

    const [
        findGameRequest,
        {
            data: games,
            isFetching,
            isLoading,
        },
    ] = useLazyFindGameQuery();

    const search = (newValue: string) => {
        findGameRequest(newValue);
    };

    const reset = () => {
        onChanged('');
        setValue('');
        setSelectedGame(undefined);
        onSelect();
    };

    const set = (val: string, game?: IGame) => {
        onChanged(val);
        setValue(val);

        setSelectedGame(game);
    };

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (readonly) return;

        const inputElementValue = event.target.value;

        if (maxLength && inputElementValue && inputElementValue.length > maxLength) return;

        let newValue: string;

        if (invalidSymbols) {
            newValue = inputElementValue.replace(invalidSymbols, '');
        } else {
            newValue = inputElementValue;
        }

        setValue(newValue);

        if (debounceTime > 0) {
            clearTimeout(timer);
            timer = setTimeout(() => {
                onChanged(newValue);
            }, debounceTime);
        } else {
            onChanged(newValue);
        }
    };

    const preloadImages = async () => {
        if (games) {
            const results = games.map((res) => {
                return new Promise((resolve) => {
                    const loadImg = new Image();
                    loadImg.src = `https://images.igdb.com/igdb/image/upload/t_thumb/${res.coverThumbId}.jpg`;
                    loadImg.onload = () => resolve([res.coverThumbId, false]);
                    loadImg.onerror = () => resolve([res.coverThumbId, true]);
                });
            });

            const states = await Promise.all(results);
            const errors = states.map((res: unknown) => {
                if (res) {
                    const [url, isError] = res as Tuple;
                    if (isError) return url as string;
                }
            });

            if (errors) {
                setErrorUrls(errors as string[]);
            }

            setIsImagesPreloaded(true);
        }
    };

    const isLoadInProgress = isLoading || isFetching || !isImagesPreloaded;

    useEffect(() => {
        if (!isFetching && (!games || games.length === 0)) {
            setSearchResults([]);
            setIsImagesPreloaded(true);
        } else if (!isFetching && games) {
            setIsImagesPreloaded(false);
            void preloadImages();
        }
    }, [games, isFetching]);

    useEffect(() => {
        if (isImagesPreloaded) {
            setSearchResults(games);
        }
    }, [isImagesPreloaded]);

    useEffect(() => {
        if (defaultValue && !defaultInitialized) {
            const defaultSelectedGame = {
                _id: defaultValue.id,
                name: defaultValue.name,
                coverThumbId: defaultValue.coverThumbId,
            } as unknown as IGame;
            setSelectedGame(defaultSelectedGame);
            setDefaultInitialized(true);
        } else if (!defaultInitialized) {
            setSelectedGame(undefined);
        } else if (defaultValue) {
            const defaultSelectedGame = {
                _id: defaultValue.id,
                name: defaultValue.name,
                coverThumbId: defaultValue.coverThumbId,
            } as unknown as IGame;
            setSelectedGame(defaultSelectedGame);
        } else {
            setSelectedGame(undefined);
        }
    }, [defaultValue]);

    return {
        searchResults,
        selectedGame,
        isLoadInProgress,
        errorUrls,
        value,
        set,
        reset,
        onChange,
        setSelectedGame,
        setIsImagesPreloaded,
    };
};
