import { ReactElement } from 'react';

export enum EAuthMode {
    DEFAULT = 'default',
    OBS = 'obs',
}

export interface IAuthData {
    authMode: EAuthMode;
    authToken: string;
    obsToken: string;
    refreshToken: string;
}

export type TAuthContext = IAuthData & {
    redirectUnauthorizedTo: ReactElement | string;
    clearTokens: () => void;
    updateToken: (accessToken?: string, refreshToken?: string) => void;
}
