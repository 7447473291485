import React from 'react';
import { Outlet } from 'react-router-dom';

import { useApp } from '@hooks';
import { ModeratorAreaPage } from '@memealerts/views';

interface IStreamerSettingsProps {
    noRightSidebar?: boolean;
}

export const ModeratorArea = (props: IStreamerSettingsProps) => {
    const app = useApp();

    return (
        <ModeratorAreaPage {...app} {...props}>
            <Outlet context={app}/>
        </ModeratorAreaPage>
    );
};
