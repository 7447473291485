import { useEffect } from 'react';

import { useCurrentUser } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';

export const RootRedirect = () => {
    const {
        goToModerationRoom,
        goToModerationStickersTab,
    } = useNavigator();

    const {
        currentUser,
    } = useCurrentUser();

    useEffect(() => {
        if (currentUser && (currentUser.flags.isMasterModer || currentUser.flags.isAdmin)) {
            goToModerationStickersTab();
        } else if (currentUser && currentUser.flags.isModer) {
            goToModerationRoom();
        }
    }, [currentUser]);

    return null;
};
