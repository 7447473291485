import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import {
    EtmaRating,
    IGame,
    IContentMark,
    ISticker,
    ITag,
    IUser, ModerationState,
    ContentMarkAction,
    TOptional, TNullable, IStickerUpdate,
} from '@typings';

interface StickerEditorParams {
    stickerId?: string;
    stickerToEdit?: ISticker;
    contentMarks?: IContentMark[];
    processStickerContentMarks?: boolean;
}

export const useStickerEditor = (params: StickerEditorParams) => {
    const {
        stickerId,
        stickerToEdit,
        contentMarks,
        processStickerContentMarks = true,
    } = params;

    const {
        moderation: {
            useLazyLoadStickerToEditQuery,
            useUpdateStickerMutation,
        },
    } = useApi();

    const [
        loadSticker,
        {
            isFetching: isStickerFetching,
        },
    ] = useLazyLoadStickerToEditQuery();

    const [
        updateSticker,
        {
            isLoading: isStickerUpdating,
        },
    ] = useUpdateStickerMutation();

    const [currentSticker, setCurrentSticker] = useState<TOptional<ISticker>>(stickerToEdit);

    const [name, setName] = useState<TOptional<string>>(stickerToEdit?.name);
    const [description, setDescription] = useState<TOptional<string>>(stickerToEdit?.description);
    const [speech2text, setSpeech2text] = useState<TOptional<string>>(stickerToEdit?.speech2text);
    const [ocrText, setOcrText] = useState<TOptional<string>>(stickerToEdit?.ocrText);
    const [music, setMusic] = useState<TOptional<string>>(stickerToEdit?.music);
    const [hasCopyright, setHasCopyright] = useState<TOptional<boolean>>(stickerToEdit?.hasCopyright);
    const [isTwitchUnsafe, setIsTwitchUnsafe] = useState<TOptional<boolean>>(stickerToEdit?.isNotTwitchSafe);
    const [etmaRating, setEtmaRating] = useState<TOptional<EtmaRating>>(stickerToEdit?.etmaRating);
    const [selectedStreamer, setSelectedStreamer] = useState<TNullable<IUser>>();

    const [selectedGame, setSelectedGame] = useState<TNullable<IGame>>();
    const [selectedTags, setSelectedTags] = useState<Array<ITag>>();
    const [defaultStreamerValue, setDefaultStreamerValue] = useState<IUser>();
    const [defaultGameValue, setDefaultGameValue] = useState<IGame>();

    const [isApprovalProhibited, setIsApprovalProhibited] = useState<boolean>(false);

    const [currentContentMarks, setCurrentContentMarks] = useState<Array<IContentMark>>([]);
    const [currentContentMarkIds, setCurrentContentMarkIds] = useState<string[]>([]);

    const loadCurrentSticker = async () => {
        if (isStickerFetching || !stickerId) return;
        const loaded = await loadSticker({ stickerId }, false).unwrap();

        if (loaded) {
            setCurrentSticker(loaded);
        }
    };

    const restoreLocalState = () => {
        if (currentSticker) {
            setName(currentSticker.name);
            setDescription(currentSticker.description);
            setSpeech2text(currentSticker.speech2text);
            setOcrText(currentSticker.ocrText);
            setMusic(currentSticker.music);
            setHasCopyright(currentSticker.hasCopyright);
            setEtmaRating(currentSticker.etmaRating);
            setSelectedTags(currentSticker.tags);
            setIsTwitchUnsafe(currentSticker.isNotTwitchSafe);

            const defaultStreamerValue = currentSticker?.streamerId
                ? {
                    id: currentSticker.streamerId,
                    avatarUrl: currentSticker.streamerAvatar,
                    name: currentSticker.streamerName,
                    channel: {
                        avatarUrl: currentSticker.streamerAvatar,
                        name: currentSticker.streamerName,
                    } }
                : undefined;

            const defaultGameValue = currentSticker?.gameId
                ? { id: currentSticker.gameId,
                    coverThumbId: currentSticker.gameCoverThumbId,
                    name: currentSticker.gameName }
                : undefined;

            setDefaultStreamerValue(!defaultStreamerValue ? defaultStreamerValue : defaultStreamerValue as unknown as IUser);

            setDefaultGameValue(!defaultGameValue ? defaultGameValue : defaultGameValue as unknown as IGame);

            if (contentMarks) {
                setCurrentContentMarks(contentMarks.filter((m) => currentSticker.contentMarks?.includes(m.id)));
                setCurrentContentMarkIds(currentSticker.contentMarks ?? []);
            }
        }
    };

    const onStreamerSelect = (streamer?: IUser) => {
        setSelectedStreamer(streamer || null);
    };

    const onGameSelect = (game?: IGame) => {
        setSelectedGame(game || null);
    };

    const onContentMarkSelect = (contentMark: IContentMark) => {
        if (currentContentMarkIds.includes(contentMark.id ?? '')) {
            setCurrentContentMarkIds(currentContentMarkIds.filter((id) => id !== contentMark.id));
            setCurrentContentMarks(currentContentMarks.filter((item) => item.id !== contentMark.id));
        } else {
            setCurrentContentMarkIds([...currentContentMarkIds, contentMark.id ?? '']);
            setCurrentContentMarks([...currentContentMarks, contentMark]);
        }
    };

    const onContentMarkRemove = (contentMark: IContentMark) => {
        if (currentContentMarkIds.includes(contentMark.id ?? '')) {
            onContentMarkSelect(contentMark);
        }
    };

    const stickerUpdate = async (update: IStickerUpdate) => {
        const result = await updateSticker(update).unwrap();

        setCurrentSticker(result);
    };

    const saveState = async (moderationState?: ModerationState, rejectReason?: string) => {
        if (currentSticker) {
            const result = await updateSticker({
                id: currentSticker.id,
                isNotTwitchSafe: isTwitchUnsafe,
                streamerId: selectedStreamer === null ? null : selectedStreamer?._id,
                gameId: selectedGame === null ? null : selectedGame?.id,
                tags: selectedTags,
                contentMarks: currentContentMarkIds,
                moderationState: moderationState ? moderationState : currentSticker.moderationState,
                rejectReason,
                name,
                description,
                speech2text,
                ocrText,
                music,
                hasCopyright,
                etmaRating,
            }).unwrap();

            setCurrentSticker(result);
        }
    };

    const processActions = () => {
        const etmaTags = currentContentMarks.filter((item) => item.actions?.includes(ContentMarkAction.ETMA));
        const rejectTags = currentContentMarks.filter((item) => item.actions?.includes(ContentMarkAction.REJECT));
        const twitchUnsafeTags = currentContentMarks.filter((item) => item.actions?.includes(ContentMarkAction.TWITCH_UNSAFE));

        if (etmaTags.length > 0 && processStickerContentMarks) {
            const maxEtma = Math.max(...etmaTags.map((t) => t.etmaRating ?? 0));

            if (!etmaRating || maxEtma > etmaRating) {
                setEtmaRating(maxEtma);
            }
        }

        if (rejectTags.length > 0) {
            setIsApprovalProhibited(true);
        } else if (isApprovalProhibited) {
            setIsApprovalProhibited(false);
        }

        if (twitchUnsafeTags.length > 0 && processStickerContentMarks) {
            setIsTwitchUnsafe(true);
        }
    };

    useEffect(() => {
        processActions();
    }, [currentContentMarks]);

    useEffect(() => {
        if (currentSticker && contentMarks) {
            restoreLocalState();
        }
    }, [currentSticker, contentMarks]);

    useEffect(() => {
        if (!stickerToEdit && !currentSticker) {
            loadCurrentSticker();
        } else if (stickerToEdit) {
            setCurrentSticker(stickerToEdit);
        } else if (currentSticker && !stickerToEdit && !stickerId) {
            setCurrentSticker(stickerToEdit);
        }
    }, [stickerId, stickerToEdit]);

    return {
        currentSticker,
        name,
        description,
        speech2text,
        ocrText,
        music,
        hasCopyright,
        etmaRating,
        defaultStreamerValue,
        defaultGameValue,
        isTwitchUnsafe,
        selectedTags,
        isApprovalProhibited,
        currentContentMarks,
        currentContentMarkIds,
        isStickerUpdating,
        isStickerFetching,

        setName,
        setDescription,
        setSpeech2text,
        setOcrText,
        setMusic,
        setHasCopyright,
        setEtmaRating,
        onStreamerSelect,
        onGameSelect,
        setSelectedTags,
        setIsTwitchUnsafe,
        onContentMarkSelect,
        onContentMarkRemove,
        saveState,
        stickerUpdate,
    };
};
