import { useEffect, useState } from 'react';

import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';
import { ISticker, IStickerUpdate, ModerationState } from '@typings';


export const useModerationRoom = () => {
    const [currentSticker, setCurrentSticker] = useState<ISticker>();
    const [lastModeratedStickerId, setLastModeratedStickerId] = useState<string>();

    const {
        moderation: {
            useUpdateStickerMutation,
            useLazyGetNextModeratedStickerQuery,
        },
    } = useApi();

    const [
        update,
        {
            isLoading: isStickerUpdating,
        },
    ] = useUpdateStickerMutation();

    const [
        getNextModeratedSticker,
        {
            data: currentModeratedSticker,
            isFetching: isCurrentStickerFetching,
        },
    ] = useLazyGetNextModeratedStickerQuery();

    const {
        notify,
    } = useNotifications();

    const reloadCurrentSticker = () => {
        getNextModeratedSticker({ skipCurrent: false });
    };

    const skipCurrentSticker = () => {
        getNextModeratedSticker({ skipCurrent: true });
    };

    const loadNextSticker = async (stickerId?: string) => {
        reloadCurrentSticker();
        setLastModeratedStickerId(stickerId);
    };

    const returnLastStickerForModeration = async () => {
        if (lastModeratedStickerId) {
            const updatedSticker = await update({ id: lastModeratedStickerId, moderationState: ModerationState.MODERATION }).unwrap();

            if (updatedSticker) {
                reloadCurrentSticker();
                setLastModeratedStickerId(undefined);
            } else {
                notify('Не удалось вернуть стикер, обратитесь к разработчику', NotificationType.ERROR);
            }
        }
    };

    const updateSticker = async (updatedData: IStickerUpdate) => {
        const updatedSticker = await update({ ...updatedData }).unwrap();

        if (updatedSticker) {
            reloadCurrentSticker();
        } else {
            notify('Не удалось обновить стикер, обратитесь к разработчику', NotificationType.ERROR);
        }
    };

    const updateLoadedSticker = (sticker: ISticker) => {
        setCurrentSticker({ ...currentSticker, ...sticker });
    };

    useEffect(() => {
        if (!currentSticker) {
            reloadCurrentSticker();
        }
    }, []);

    useEffect(() => {
        setCurrentSticker(currentModeratedSticker);
    }, [currentModeratedSticker]);

    return {
        currentSticker,
        lastModeratedStickerId,
        isCurrentStickerFetching,
        showLoader: isCurrentStickerFetching || isStickerUpdating,

        loadNextSticker,
        returnLastStickerForModeration,
        updateSticker,
        updateLoadedSticker,
        skipCurrentSticker,
    };
};
