import {
    Button,
    Column,
    EColumnAlignment,
    EComponentColor,
    EComponentSize,
    Label,
    Loader,
    Row,
    useModals,
} from '@hyperclap/ui';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconMagnifierLine } from '@assets/images/svg';
import { StickersListMode, TApp, useViewerInfo, ViewerInfoMode } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { IUser } from '@typings';
import { Empty, ITabParams, Tabs } from '@views/fragments';
import { DeleteOrRestoreUserPopup } from '@views/modals';

import { EventsTable } from './components/EventsTable';
import { WalletsTable } from './components/WalletsTable';
import s from './ViewerView.scss';

export interface IViewerViewProps extends TApp {
    userId: string;
}

export const ViewerView = (props: IViewerViewProps) => {
    const { userId: userIdParam } = useParams();
    const {
        currentUser: {
            currentUser,
        },
        translation: {
            t,
        },
        userId,
    } = props;

    const [viewerInfoMode, setViewerInfoMode] = useState<ViewerInfoMode>(ViewerInfoMode.EVENTS);

    const {
        lastData,
        currentStats,
        isLoadingPage,
        isFetchingPage,
        isEndReached,
        isNotFound,
        loadNextPage,
        giveBonus,
        deleteUser,
        restoreUser,
    } = useViewerInfo({ userId });

    const { goToViewersSearch, goToModerationStickersTab } = useNavigator();
    const { showModal } = useModals();

    const formatDate = (date: Date | number) => {
        const d = typeof date === 'number' ? new Date(date) : date;

        return d.toLocaleTimeString('ru', {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
        });
    };

    const onDeleteOrRestoreUser = (userId: string, callback: (userId: string) => void, caption: string) => {
        showModal({
            content: (
                <DeleteOrRestoreUserPopup
                    onAcceptClick={callback}
                    buttonCaption={caption}
                    userId={userId}
                />
            ),
            options: {},
        });
    };

    const onDeleteUserCallback = async (userId: string) => {
        await deleteUser(userId);
    };

    const onRestoreCallback = async (userId: string) => {
        await restoreUser(userId);
    };

    const TABS: Array<ITabParams<ViewerInfoMode>> = [
        { caption: t('moderator-area.viewers.view.events'), tag: ViewerInfoMode.EVENTS },
        { caption: t('moderator-area.viewers.view.wallets'), tag: ViewerInfoMode.WALLETS },
    ];

    const onTabSelected = (mode: ViewerInfoMode = ViewerInfoMode.EVENTS) => {
        setViewerInfoMode(mode);
    };

    const viewer = lastData?.viewer;

    const getAuthorizationWayText = (viewer: IUser) => {
        return viewer.linkedAccounts.google ? `Google (${viewer.linkedAccounts.google.email})`
            : viewer.linkedAccounts.vk ? `VK (${viewer.linkedAccounts.vk.username})`
                : `Twitch (${viewer.linkedAccounts.twitch.username})`;
    };

    useEffect(() => {
        if (lastData?.viewer && !isFetchingPage) {
            if (!userIdParam || userId !== userIdParam) {
                goToViewersSearch(lastData?.viewer.id);
            }
        } else if (!isFetchingPage && isNotFound) {
            goToViewersSearch();
        }
    }, [lastData, isFetchingPage, isNotFound]);

    return (
        <Column className={s.statsContainer}>
            {viewer &&
                <Row>
                    <Column grow rowGap={20}>
                        {viewer.deletedAt &&
                            <Row>
                                <Column>
                                    <Row>
                                        <Label caption={t('moderator-area.viewers.view.deleted-user')} size={EComponentSize.LARGE}/>
                                    </Row>
                                    <Row>
                                        <Label
                                            caption={
                                                t('moderator-area.viewers.view.deleted-at', { date: formatDate(viewer.deletedAt) })
                                            }
                                            muted
                                            style={{ paddingTop: 0 }}
                                            size={EComponentSize.SMALL}
                                        />
                                    </Row>
                                </Column>
                                <Column grow alignment={EColumnAlignment.END}>
                                    {(currentUser?.flags.isAdmin || currentUser?.flags.isMasterModer) &&
                                        <Button
                                            caption={t('moderator-area.viewers.view.restore-user')}
                                            color={EComponentColor.WARNING}
                                            onClick={() =>
                                                onDeleteOrRestoreUser(
                                                    lastData.viewer.id,
                                                    onRestoreCallback,
                                                    t('moderator-area.viewers.view.restore'),
                                                )}
                                        />
                                    }
                                </Column>
                            </Row>
                        }
                        {!viewer.deletedAt &&
                            <Row>
                                <Column>
                                    <Row>
                                        <Label caption={viewer.name} size={EComponentSize.LARGE} onClick={
                                            () => goToModerationStickersTab(StickersListMode.APPROVED, viewer?.id)
                                        }/>
                                    </Row>
                                    <Row>
                                        <Label
                                            caption={t('moderator-area.viewers.view.auth-type', { type: getAuthorizationWayText(viewer) })}
                                            muted
                                            style={{ paddingTop: 0 }}
                                            size={EComponentSize.SMALL}/>
                                    </Row>
                                </Column>
                                <Column grow alignment={EColumnAlignment.END}>
                                    {(currentUser?.flags.isAdmin || currentUser?.flags.isMasterModer) &&
                                        <Button
                                            className={s.deleteOrRestoreButton}
                                            caption={t('moderator-area.viewers.view.delete-user')}
                                            color={EComponentColor.DANGER}
                                            onClick={() => onDeleteOrRestoreUser(
                                                lastData.viewer.id,
                                                onDeleteUserCallback,
                                                t('moderator-area.viewers.view.delete'),
                                            )}
                                        />
                                    }
                                </Column>
                            </Row>
                        }
                        <Row>
                            <Tabs activeTab={viewerInfoMode} onSelectionChange={onTabSelected} tabs={TABS}/>
                        </Row>
                    </Column>
                </Row>
            }
            {(currentStats.length > 0 && viewerInfoMode === ViewerInfoMode.EVENTS) &&
                <Row>
                    <EventsTable {...props} events={currentStats} />
                </Row>
            }
            {(currentStats.length > 0 && viewerInfoMode === ViewerInfoMode.WALLETS) &&
                <Row>
                    <WalletsTable {...props} wallets={lastData?.wallets} giveBonus={giveBonus} isWalletsFetching={isFetchingPage}/>
                </Row>
            }

            {!isEndReached && currentStats.length > 0 &&
                    !isLoadingPage && !isFetchingPage &&
                    viewerInfoMode === ViewerInfoMode.EVENTS &&
                <Row>
                    <Button
                        caption={t('moderator-area.viewers.view.load-more')}
                        style={{ marginTop: 15 }}
                        className={s.loadMoreButton}
                        onClick={loadNextPage}
                    />
                </Row>
            }
            {!(isLoadingPage || isFetchingPage) && isNotFound &&
                <Row>
                    <Empty style={{ marginTop: 20 }} icon={<IconMagnifierLine/>} text={t('moderator-area.viewers.view.not-found')}/>
                </Row>
            }
            {(isLoadingPage || isFetchingPage) && (viewerInfoMode !== ViewerInfoMode.WALLETS || currentStats.length == 0) &&
                <Row>
                    <Loader style={{ marginTop: 20, marginBottom: 3 }}/>
                </Row>
            }
        </Column>
    );
};
