import { Column, ITextFieldAction, ITextFieldMethods, Row, SearchField } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconCloseLine, IconMagnifierLine } from '@assets/images/svg';
import { useAppContext } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { ViewerView } from '@views';

import s from './ViewersSearch.scss';

const checkForHexRegExp = new RegExp('^[0-9a-fA-F]{24}$');

enum SearchTextFieldAction {
    RESET = 'reset',
}

export const ViewersSearch = () => {
    const { userId } = useParams();

    const app = useAppContext();
    const {
        translation: { t },
    } = app;

    const { goToViewersSearch } = useNavigator();

    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    const [enteredSearchQuery, setEnteredSearchQuery] = useState(userId ?? '');

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === SearchTextFieldAction.RESET) {
            setEnteredSearchQuery('');
            goToViewersSearch();
        }
    };

    const searchFieldActions = [
        {
            icon: <IconCloseLine/>,
            name: SearchTextFieldAction.RESET,
            keyboardKeys: ['Escape'],
            hidden: !enteredSearchQuery,
        },
    ];

    const isValidObjectId = (id: string) => {
        if (id == null || id == '') return true;

        return id.length === 12 || (id.length === 24 && checkForHexRegExp.test(id));
    };

    const isValidEnteredId = isValidObjectId(enteredSearchQuery);

    useEffect(() => {
        if (userId) {
            setEnteredSearchQuery(userId);
        }
    }, [userId]);

    useEffect(() => {
        if (userId && enteredSearchQuery !== userId) {
            goToViewersSearch(userId);
        }
    }, [enteredSearchQuery]);

    return (
        <>
            <Row className={s.blockRow} margin={{ top: 20 }}>
                <SearchField
                    tag={<IconMagnifierLine/>}
                    placeholder={t('moderator-area.viewers.search.viewer-id')}
                    defaultValue={enteredSearchQuery}
                    debounceTime={500}
                    hasError={!isValidEnteredId}
                    errorMessage={t('moderator-area.viewers.search.invalid-id')}
                    actions={searchFieldActions}
                    methodsRef={searchInputMethodsRef}
                    onAction={onSearchTextFieldAction}
                    onChanged={setEnteredSearchQuery}
                />
            </Row>
            <Row margin={{ top: 23 }}>
                <Column grow>
                    {enteredSearchQuery && isValidEnteredId &&
                        <ViewerView {...app} userId={enteredSearchQuery} />
                    }
                </Column>
            </Row>
        </>
    );
};
