import React, { ReactElement } from 'react';

import s from './ProfileMenuItem.scss';

interface ProfileMenuItemProps {
    caption: string;
    icon?: ReactElement;
    onClick?: () => void;
}

export const ProfileMenuItem = (props: ProfileMenuItemProps) => {
    const {
        caption,
        icon,
        onClick,
    } = props;

    return (
        <div className={s.profileMenuItem} onClick={onClick}>
            <div className={s.profileMenuItemIcon}>
                { icon }
            </div>
            <div>
                { caption }
            </div>
        </div>
    );
};
