/** Default value of newbie action */
export const DEFAULT_NEWBIE_ACTION = 3;

/** Default value of welcome bonus */
export const DEFAULT_WELCOME_BONUS = 5;

/** Default send delay between two stickers */
export const DEFAULT_SEND_DELAY = 1000;

/** Default sound volume which is used to play sticker */
export const DEFAULT_STICKER_PRICE = 10;

/** Default sound volume which is used to play sticker */
export const DEFAULT_SOUND_VOLUME = 90;

/** Sticker search query parameter name */
export const STICKER_SEARCH_QUERY_NAME = 'query';

/** Sticker search sorting field parameter name */
export const STICKER_SEARCH_SORT_NAME = 'sort';

/** Sticker search order parameter name */
export const STICKER_SEARCH_ORDER_NAME = 'order';

/** Sticker search content marks parameter name */
export const STICKER_SEARCH_CONTENT_MARKS_NAME = 'filter';
