import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { ModerationRoom, StickerEditor, Stickers, StickersSearch } from '@views';
import { Filters } from '@views/pages/ModeratorArea/pages/Filters';
import { Streamers, StreamersSearch } from '@views/pages/ModeratorArea/pages/Streamers';
import { Viewers, ViewersSearch } from '@views/pages/ModeratorArea/pages/Viewers';

export const ModerationRoutes: Array<RouteObject> = [
    {
        path: 'room',
        element: <ModerationRoom/>,
        children: [
            {
                index: true,
                element: <Navigate to="editor" replace />,
            },
            {
                path: 'editor',
                element: <StickerEditor/>,
            },
        ],
    },
    {
        path: 'stickers',
        element: <Stickers/>,
        children: [
            {
                index: true,
                element: <Navigate to="search" replace />,
            },
            {
                path: 'search/:mode?/:scrollIndex?',
                element: <StickersSearch/>,
            },
            {
                path: 'editor/:stickerId',
                element: <StickerEditor/>,
            },
        ],
    },
    {
        path: 'viewers',
        element: <Viewers/>,
        children: [
            {
                index: true,
                element: <Navigate to="search" replace />,
            },
            {
                path: 'search/:userId?',
                element: <ViewersSearch/>,
            },
        ],
    },
    {
        path: 'streamers',
        element: <Streamers/>,
        children: [
            {
                index: true,
                element: <Navigate to="search" replace />,
            },
            {
                path: 'search/:query?',
                element: <StreamersSearch/>,
            },
        ],
    },
    {
        path: 'filters',
        element: <Filters/>,
    },
];
