import { Column, ITextFieldAction, ITextFieldMethods, Row, SearchField } from '@hyperclap/ui';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { IconCloseLine, IconMagnifierLine } from '@assets/images/svg';
import { useAppContext } from '@hooks';
import { useNavigator } from '@hooks/app/navigator';
import { StreamerView } from '@views';

import s from './StreamersSearch.scss';

enum SearchTextFieldAction {
    RESET = 'reset',
}

export const StreamersSearch = () => {
    const { query } = useParams();

    const app = useAppContext();
    const {
        translation: { t },
    } = app;

    const { goToStreamersSearch } = useNavigator();

    const searchInputMethodsRef = useRef<ITextFieldMethods>(null);

    const [enteredSearchQuery, setEnteredSearchQuery] = useState(query ?? '');

    const onSearchTextFieldAction = (action: ITextFieldAction) => {
        if (action.name === SearchTextFieldAction.RESET) {
            setEnteredSearchQuery('');
            goToStreamersSearch();
        }
    };

    const searchFieldActions = [
        {
            icon: <IconCloseLine/>,
            name: SearchTextFieldAction.RESET,
            keyboardKeys: ['Escape'],
            hidden: !enteredSearchQuery,
        },
    ];

    useEffect(() => {
        if (query) {
            setEnteredSearchQuery(query);
        }
    }, [query]);

    useEffect(() => {
        if (query && enteredSearchQuery !== query) {
            goToStreamersSearch(query);
        }
    }, [enteredSearchQuery]);

    return (
        <>
            <Row className={s.blockRow} margin={{ top: 20 }}>
                <SearchField
                    tag={<IconMagnifierLine/>}
                    placeholder={t('moderator-area.streamers.search.streamer-link')}
                    defaultValue={enteredSearchQuery}
                    debounceTime={500}
                    actions={searchFieldActions}
                    methodsRef={searchInputMethodsRef}
                    onAction={onSearchTextFieldAction}
                    onChanged={setEnteredSearchQuery}
                />
            </Row>
            <Row margin={{ top: 23 }}>
                <Column grow>
                    {enteredSearchQuery &&
                        <StreamerView {...app} query={enteredSearchQuery} />
                    }
                </Column>
            </Row>
        </>
    );
};
