import React from 'react';

import { useApp } from '@hooks';

import { Desktop } from './layouts';

export const Auth = () => {
    const app = useApp();

    return (
        <Desktop {...app}/>
    );
};
