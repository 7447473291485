import { Logger } from '@hyperclap/ui';
import React from 'react';
import { RouteObject } from 'react-router-dom';

import {
    AuthRedirect,
    RootRedirect,
} from '@views';

import { AuthRequired } from './guards';

const logger = new Logger({ target: 'Routing', showTimestamp: true });
logger.trace(`Prepare routes`);

export const routing: Array<RouteObject> = [
    // Home page
    {
        path: '/',
        element: <AuthRequired><RootRedirect/></AuthRequired>,
    },
    // Special routes
    {
        path: '/auth/redirect',
        element: <AuthRedirect />,
    },
];
