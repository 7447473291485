import React from 'react';
import { Navigate, RouteObject } from 'react-router-dom';

import { mergeObjectArrays } from '@common';
import { ModeratorArea } from '@memealerts/views/pages/ModeratorArea';
import { routing as baseRouting } from '@routing';
import { AuthRequired } from '@routing/guards';
import { ModerationRoutes } from '@routing/routes';

export const memeAlertsRouting = mergeObjectArrays<RouteObject>(
    baseRouting,
    [
        {
            path: '/moderation',
            element: <AuthRequired>
                <ModeratorArea noRightSidebar/>
            </AuthRequired>,
            children: [{
                index: true,
                element: <Navigate to="room" replace />,
            }, ...ModerationRoutes],
        },
    ],
    'path',
);
