import { Button, Column, EComponentColor, EComponentSize, Label, Loader, Row, TextArea, TextField } from '@hyperclap/ui';
import { t } from 'i18next';
import React, { useState } from 'react';

import { useBadWords } from '@hooks';

import s from './BadWordsEditor.scss';

interface IBadWordsEditorProps {
    dictionaryName: string;
}

export const BadWordsEditor = (props: IBadWordsEditorProps) => {
    const {
        dictionaryName,
    } = props;

    const [testQuery, setTestQuery] = useState<string>('');

    const {
        hasErrors,
        joinedBadWords,
        checkResult,
        isBadWordsFetching,
        isBadWordsLoading,
        isCheckFetching,
        onJoinedBadWordsChange,
        saveBadWords,
    } = useBadWords({ dictionaryName, testQuery });

    return (
        <Column grow rowGap={15}>
            <Row style={{ marginTop: 10 }}>
                <Label caption={t('moderator-area.filters.word-list')} size={EComponentSize.LARGE}/>
            </Row>
            <Row>
                <Column style={{ position: 'relative' }}>
                    <TextArea
                        width={500}
                        maxRows={20}
                        defaultValue={joinedBadWords}
                        placeholder={t('moderator-area.filters.placeholder')}
                        onChange={(value) => onJoinedBadWordsChange(value?.toString())}
                    />
                    {hasErrors && <Label
                        caption={t('moderator-area.filters.error')}
                        size={EComponentSize.SMALL}
                        color={EComponentColor.DANGER}
                    />}
                    {(isBadWordsFetching || isBadWordsLoading) && (
                        <div className={s.loader}>
                            <Loader/>
                        </div>
                    )}
                </Column>
            </Row>
            <Row>
                <Column width={500}>
                    <Row columnGap={10}>
                        <Column width={230}>
                            <Row className={s.blockRow}>
                                <TextField
                                    placeholder={t('moderator-area.filters.test-text')}
                                    debounceTime={500}
                                    defaultValue={testQuery}
                                    onChanged={(value) => setTestQuery(value)}
                                />
                            </Row>
                        </Column>
                        <Column grow>
                            <Row className={s.blockRow}>
                                {!isCheckFetching
                                    ? <TextField
                                        readonly
                                        placeholder={t('moderator-area.filters.test-result')}
                                        defaultValue={checkResult}
                                    />
                                    : <Loader containerClassName={s.loader} size={EComponentSize.SMALL}/>
                                }
                            </Row>
                        </Column>
                    </Row>
                </Column>
            </Row>
            <Row>
                {!isBadWordsFetching && !isBadWordsLoading && joinedBadWords &&
                    <Button
                        caption={t('common.save')}
                        color={EComponentColor.WARNING}
                        disabled={!joinedBadWords || joinedBadWords.length === 0}
                        onClick={saveBadWords}
                    />
                }
            </Row>
        </Column>
    );
};
