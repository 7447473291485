export enum EtmaRating {
    E,
    T,
    M,
    A,
}

export enum StickerSize {
    XSMALL = 'xsmall',
    SMALL = 'small',
    MEDIUM = 'medium',
    LARGE = 'large',
}

export enum StickerSendTopic {
    DEFAULT = 'Default',
    SEARCH = 'Search',
    TOP = 'Top',
    POPULAR = 'Popular',
    LAST = 'Last',
    CHANNEL = 'Channel',
    FAVORITES = 'Favorites',
    MY_CREATED = 'MyCreated',
    THEMATIC_SET = 'Theme',
}

export interface IVideoData {
    width: number;
    height: number;
    aspectRatio: number;
    isTransparent: boolean;
}

export interface IStickerCustomSettings {
    _id?: string; // TODO: Remove it
    disableSticker?: boolean;
    customPriceEnabled?: boolean;
    customPrice?: number;
    customVolumeEnabled?: boolean;
    customVolume?: number;
}

export interface ITag {
    id?: string;
    _id?: string;
    name: string;
    usageCount?: number,
    createdAt?: number;
}

export interface IGame {
    _id: string;
    id: string;
    name: string;
    coverThumbId?: string,
}

export interface IModerationLog {
    _id: string;
    id: string;
    kind: string;
    stickerId: string,
    userId?: string,
    userName?: string,
    timestamp: number,
}

export interface IStickerInsights {
    sticker_id: string;
    current_worker: string;
    next_worker: string;
    completed_workers: Array<string>;
    insights?: {
        WhisperWorker?: {
            text?: string;
        };
        YandexWorker?: {
            adult: number;
            gruesome: number;
            has_text: number;
            watermarks: number;
            text: string;
        };
        AuddWorker?: {
            status: string;
            result?: {
                artist: string;
                title: string;
                album: string;
                release_date: string;
                label: string;
                timecode: string;
                song_link: string;
            };
        };
        OpenaiWorker?: {
            text?: {
                rating: EtmaRating | -1;
                BT1?: number;
                BT2?: number;
                BT3?: number;
                BT4?: number;
                BT5?: number;
                BT6?: number;
                S1?: number;
                S2?: number;
                S3?: number;
                S4?: number;
                A0?: number;
                A1?: number;
                A2?: number;
                A3?: number;
                A4?: number;
                W1?: number;
                W2?: number;
                UT1?: number;
                UT2?: number;
                UT3?: number;
                UT4?: number;
                UT5?: number;
                UT6?: number;
                UT7?: number;
                UT8?: number;
            };
            image?: {
                rating: EtmaRating | -1;
                BT1?: number;
                BT2?: number;
                BT3?: number;
                BT4?: number;
                BT5?: number;
                BT6?: number;
                BT7?: number;
                BT8?: number;
                S1?: number;
                N1?: number;
                N2?: number;
                N3?: number;
                V1?: number;
                V2?: number;
                V3?: number;
                V4?: number;
                UT1?: number;
                UT2?: number;
                UT3?: number;
                UT4?: number;
                UT5?: number;
                UT6?: number;
                UT7?: number;
                UT8?: number;
                UT9?: number;
                UT10?: number;
                UT11?: number;
            };
        };
        QdrantDeduplicationWorker?: {
            is_duplicate_of: string;
            is_duplicate: boolean;
        };
        QdrantDeepfaceWorker?: {
            streamer_id: string;
            score: number;
        };
    };
    error_text?: string;
    updated_at: number;
    is_processed: boolean;
    processed_at?: number;
}

export interface ISticker {
    id: string;
    name: string;
    description: string;
    stickerUrl?: string;
    fallbackUrl?: string;
    stickerPreview? : string;
    stickerAnimatedPreview? : string;
    creatorId: string;
    streamerId?: string;
    gameId?: string;
    usageCount?: number;
    moderationState: string;
    isAdult?: boolean;
    isNotTwitchSafe?: boolean;
    hasCopyright?: boolean;
    isOnTop?: boolean;
    isFavorite?: boolean;
    likedCount?: number;
    isAddedToChannel?: boolean;
    categories?: string[];
    createdAt?: number;
    creatorName?: string;
    creatorAvatar?: string;
    moderatedAt?: number;
    moderatorName?: string;
    moderatorAvatar?: string;
    streamerName?: string;
    streamerAvatar?: string;
    gameName?: string;
    gameCoverThumbId?: string;
    customSettings?: IStickerCustomSettings;
    videoData?: IVideoData;
    etmaRating?: EtmaRating;
    disableCount?: number;
    disabledAt?: number;
    creatorTotalApproved?: number;
    creatorTotalApprovedPercent?: number;
    creatorTotalDeclined?: number;
    tags?: ITag[];
    speech2text?: string;
    ocrText?: string;
    music?: string;
    isAdultProb?: number;
    contentMarks?: string[];
    moderationLog?: IModerationLog[];
    insights?: IStickerInsights;
}

export interface IStickerCreationParams {
    name: string;
    description: string;
    streamerId?: string;
    gameId?: string;
    tags?: ITag[];
    token: string;
}

export interface IFavoriteStickersLoadParams {
    onlySent?: boolean;
    onlyFavorites?: boolean;
}

export interface IChannelStickersLoadParams {
    onlyBlackList?: boolean;
}

export interface IStickerCustomSettingsUpdateParams {
    stickerId: string;
    stickerCustomSettings: IStickerCustomSettings;
}

export interface ISendStickerParams {
    toChannel: string;
    fullscreen?: boolean;
    stickerId: string;
    topic: StickerSendTopic,
    name?: string;
    isMemePartyActive?: boolean;
    message?: string;
    isSoundOnly?: boolean;
}

export interface ISendMemeCannonStickerParams {
    toChannel: string;
    stickerId: string;
    topic: StickerSendTopic,
    count: number;
    name?: string;
    isMemePartyActive?: boolean;
    message?: string;
    isSoundOnly?: boolean;
}

export interface IMassStickersUpdate {
    query: string;
    streamerId?: string | null;
    gameId?: string | null;
    addedTags?: ITag[];
    removedTags?: ITag[];
    isNotTwitchSafe?: boolean;
}

export interface IStickerUpdate {
    id: string;
    name?: string;
    description?: string;
    moderationState?: string;
    isAdult?: boolean;
    isNotTwitchSafe?: boolean;
    hasCopyright?: boolean;
    isOnTop?: boolean;
    etmaRating?: EtmaRating;
    streamerId?: string | null;
    gameId?: string | null;
    tags?: ITag[];
    rejectReason?: string;
    speech2text?: string;
    ocrText?: string;
    music?: string;
    contentMarks?: string[];
}
