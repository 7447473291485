import { useDeviceInfo, useModals } from '@hyperclap/ui';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';

import { useStickerCreation } from '@hooks/app/creator';
import { useLeftSideMenu } from '@hooks/app/menu';
import { useContentMarks } from '@hooks/app/moderator-area';

import { useLogin } from './login';
import { useNavigator } from './navigator';
import { useSettings } from './settings';
import { useCurrentUser } from './users';
import { useWebSocket } from '../websocket';

export const useApp = () => {
    const { streamerChannelName } = useParams();

    useWebSocket({ clientId: 'memealerts-streamer-area', queryParams: { streamerName: streamerChannelName } });

    const currentUser = useCurrentUser();
    const contentMarks = useContentMarks({
        currentUser: currentUser.currentUser,
    });
    const deviceInfo = useDeviceInfo();
    const login = useLogin();
    const leftSideMenu = useLeftSideMenu();
    const modals = useModals();
    const navigation = useNavigator();
    const settings = useSettings();
    const stickersCreation = useStickerCreation();
    const translation = useTranslation();

    return {
        contentMarks,
        currentUser,
        deviceInfo,
        login,
        menu: {
            leftSideMenu,
        },
        modals,
        navigation,
        settings,
        stickersCreation,
        translation,

        streamerChannelName,
    };
};

export type TApp = ReturnType<typeof useApp>;

export const useAppContext = () => useOutletContext<TApp>();
