import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';

import { TApp, useModerationRoom, useModerationShift } from '@hooks';
import { StickerEditorMode } from '@views/fragments';

interface IModerationRoomProps extends TApp {
}

export const ModerationRoom = () => {
    const ctx = useOutletContext<IModerationRoomProps>();

    const {
        currentSticker,
        lastModeratedStickerId,
        showLoader,
        loadNextSticker,
        skipCurrentSticker,
        returnLastStickerForModeration,
    } = useModerationRoom();

    const {
        currentModerationShift,
    } = useModerationShift({ currentUser: ctx.currentUser.currentUser, active: true });

    return (
        <>
            <Outlet context=
                {{ ...ctx,
                    stickerToEdit:
                    currentSticker,
                    stickerEditorMode:
                    StickerEditorMode.MODERATION,
                    isStickerToEditFetching:
                    showLoader,
                    moderationShift: currentModerationShift,
                    onBackButtonClick: lastModeratedStickerId ? returnLastStickerForModeration : undefined,
                    onPostponeButtonClick: skipCurrentSticker,
                    onAfterSave: () => loadNextSticker(currentSticker?.id),
                }}
            />
        </>
    );
};
