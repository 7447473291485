// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BadWordsEditor__blockRow--GPjAr {
  display: block;
}

.BadWordsEditor__loader--kYBH2 {
  position: absolute;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/base/views/fragments/BadWordsEditor/BadWordsEditor.scss"],"names":[],"mappings":"AAAA;EACI,cAAA;AACJ;;AAEA;EACI,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;EACA,YAAA;AACJ","sourcesContent":[".blockRow {\r\n    display: block;\r\n}\r\n\r\n.loader {\r\n    position: absolute;\r\n    display: flex;\r\n    align-items: center;\r\n    width: 100%;\r\n    height: 100%;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"blockRow": `BadWordsEditor__blockRow--GPjAr`,
	"loader": `BadWordsEditor__loader--kYBH2`
};
export default ___CSS_LOADER_EXPORT___;
